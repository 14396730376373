import revive_payload_client_O4PavZS8un from "/opt/buildhome/repo/node_modules/.pnpm/nuxt@3.15.4_rollup@4.34.8_sass@1.85.0_typescript@5.7.3_vite@6.1.1/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_OvVZG5eUYR from "/opt/buildhome/repo/node_modules/.pnpm/nuxt@3.15.4_rollup@4.34.8_sass@1.85.0_typescript@5.7.3_vite@6.1.1/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_el2e7NFmQM from "/opt/buildhome/repo/node_modules/.pnpm/nuxt@3.15.4_rollup@4.34.8_sass@1.85.0_typescript@5.7.3_vite@6.1.1/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import payload_client_eye0CCFi6u from "/opt/buildhome/repo/node_modules/.pnpm/nuxt@3.15.4_rollup@4.34.8_sass@1.85.0_typescript@5.7.3_vite@6.1.1/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_kqr0HibkTY from "/opt/buildhome/repo/node_modules/.pnpm/nuxt@3.15.4_rollup@4.34.8_sass@1.85.0_typescript@5.7.3_vite@6.1.1/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_yCmiy3A6y3 from "/opt/buildhome/repo/node_modules/.pnpm/nuxt@3.15.4_rollup@4.34.8_sass@1.85.0_typescript@5.7.3_vite@6.1.1/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_sKjNOdyxTE from "/opt/buildhome/repo/node_modules/.pnpm/nuxt@3.15.4_rollup@4.34.8_sass@1.85.0_typescript@5.7.3_vite@6.1.1/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import plugin_vue3_OjXqdJcUAc from "/opt/buildhome/repo/node_modules/.pnpm/@pinia+nuxt@0.5.5_typescript@5.7.3_vue@3.5.13/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.js";
import components_plugin_KR1HBZs4kY from "/opt/buildhome/repo/.nuxt/components.plugin.mjs";
import prefetch_client_nOIP4vIT1y from "/opt/buildhome/repo/node_modules/.pnpm/nuxt@3.15.4_rollup@4.34.8_sass@1.85.0_typescript@5.7.3_vite@6.1.1/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import plugin_ZXBW6S44aP from "/opt/buildhome/repo/node_modules/.pnpm/dayjs-nuxt@2.1.11/node_modules/dayjs-nuxt/dist/runtime/plugin.js";
import switch_locale_path_ssr_VRy9PPvUMF from "/opt/buildhome/repo/node_modules/.pnpm/@nuxtjs+i18n@8.5.6_rollup@4.34.8_vue@3.5.13/node_modules/@nuxtjs/i18n/dist/runtime/plugins/switch-locale-path-ssr.js";
import i18n_8afUOkALrw from "/opt/buildhome/repo/node_modules/.pnpm/@nuxtjs+i18n@8.5.6_rollup@4.34.8_vue@3.5.13/node_modules/@nuxtjs/i18n/dist/runtime/plugins/i18n.js";
import slideovers_Xu9TBcDcBW from "/opt/buildhome/repo/node_modules/.pnpm/@nuxt+ui@2.21.0_axios@1.7.9_typescript@5.7.3_vite@6.1.1_vue@3.5.13/node_modules/@nuxt/ui/dist/runtime/plugins/slideovers.js";
import modals_rX0SUNN2Sq from "/opt/buildhome/repo/node_modules/.pnpm/@nuxt+ui@2.21.0_axios@1.7.9_typescript@5.7.3_vite@6.1.1_vue@3.5.13/node_modules/@nuxt/ui/dist/runtime/plugins/modals.js";
import colors_tYaml6Mqcj from "/opt/buildhome/repo/node_modules/.pnpm/@nuxt+ui@2.21.0_axios@1.7.9_typescript@5.7.3_vite@6.1.1_vue@3.5.13/node_modules/@nuxt/ui/dist/runtime/plugins/colors.js";
import plugin_client_eNelkHx7DD from "/opt/buildhome/repo/node_modules/.pnpm/@nuxtjs+color-mode@3.5.2/node_modules/@nuxtjs/color-mode/dist/runtime/plugin.client.js";
import plugin_IV8c2dwvni from "/opt/buildhome/repo/node_modules/.pnpm/@nuxt+icon@1.10.3_vite@6.1.1_vue@3.5.13/node_modules/@nuxt/icon/dist/runtime/plugin.js";
import api_GFfDXud5Cr from "/opt/buildhome/repo/plugins/api.ts";
import portal_MiZwRrPxBy from "/opt/buildhome/repo/plugins/portal.ts";
export default [
  revive_payload_client_O4PavZS8un,
  unhead_OvVZG5eUYR,
  router_el2e7NFmQM,
  payload_client_eye0CCFi6u,
  navigation_repaint_client_kqr0HibkTY,
  check_outdated_build_client_yCmiy3A6y3,
  chunk_reload_client_sKjNOdyxTE,
  plugin_vue3_OjXqdJcUAc,
  components_plugin_KR1HBZs4kY,
  prefetch_client_nOIP4vIT1y,
  plugin_ZXBW6S44aP,
  switch_locale_path_ssr_VRy9PPvUMF,
  i18n_8afUOkALrw,
  slideovers_Xu9TBcDcBW,
  modals_rX0SUNN2Sq,
  colors_tYaml6Mqcj,
  plugin_client_eNelkHx7DD,
  plugin_IV8c2dwvni,
  api_GFfDXud5Cr,
  portal_MiZwRrPxBy
]